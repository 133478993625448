<!--
 * @Description: 属性配置新
 * @Author: WuPuyi
 * @LastEditors: ChenXueLin
 * @Date: 2021-04-19 11:33:49
 * @LastEditTime: 2022-06-14 13:42:55
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <div class="switch-page-button">
            <el-button
              round
              :class="[clickIndex == index ? 'activeButton' : '']"
              @click="switchPage(index)"
              v-for="(btn, index) in configList"
              :key="index"
              >{{ btn.combinationName }}</el-button
            >
          </div>
          <el-form
            ref="form"
            label-width="120px"
            v-if="configList && configList.length"
          >
            <template>
              <div class="edit-content">
                <el-row>
                  <div
                    v-for="config in configList[clickIndex]['terminalAttrList']"
                    :key="config.requireKey"
                  >
                    <el-col :span="config.objectType == 'string' ? 8 : 24">
                      <el-form-item
                        :label="config.requireName"
                        prop="islock"
                        v-if="config.objectType == 'string'"
                      >
                        <el-radio-group
                          v-model="config.requireValue"
                          :disabled="config.indexSort == 999 ? true : false"
                          v-if="
                            !(
                              config.requireKey == 'oilControl' ||
                              config.requireKey == 'equipSourceId'
                            )
                          "
                        >
                          <el-radio label="1">是</el-radio>
                          <el-radio label="0">否</el-radio>
                        </el-radio-group>
                        <span v-else>:{{ config.requireValue }}</span>
                      </el-form-item>

                      <el-form-item
                        :label="config.requireName"
                        v-if="
                          config.objectType == 'list' &&
                            (config.requireKey != 'sensor' ||
                              (config.requireKey == 'sensor' &&
                                configList[clickIndex].sensorAppList &&
                                configList[clickIndex].sensorAppList.length))
                        "
                      >
                        <div v-if="config.requireKey != 'sensor'">
                          <el-checkbox-group
                            v-model="config.channel"
                            :disabled="config.indexSort == 999 ? true : false"
                          >
                            <el-checkbox
                              :label="item.value"
                              v-for="item in keyConfig[config.requireKey]"
                              :key="item.value"
                              :disabled="
                                config.isReadOnlyChannel &&
                                config.isReadOnlyChannel.includes(item.value)
                                  ? true
                                  : false
                              "
                              >{{ item.title }}</el-checkbox
                            >
                          </el-checkbox-group>
                        </div>
                        <!-- 传感器应用后续添加 -->
                        <div v-else>
                          <el-checkbox-group v-model="config.channel">
                            <el-checkbox
                              :label="item.id"
                              v-for="item in configList[clickIndex]
                                .sensorAppList"
                              :key="item.id"
                              >{{ item.appName }}</el-checkbox
                            >
                          </el-checkbox-group>
                        </div>
                      </el-form-item>
                    </el-col>

                    <!-- TODO: 传感器应用后续添加 -->
                    <!-- <el-form-item
                      label="传感器应用"
                      v-if="
                        config.objectType == 'list' &&
                          config.requireKey == 'sensor' &&
                          configList &&
                          configList[clickIndex].sensorAppList.length
                      "
                    >
                      <el-checkbox-group v-model="config.channel">
                        <el-checkbox
                          :label="item.id"
                          v-for="item in configList[clickIndex].sensorAppList"
                          :key="item.id"
                          >{{ item.appName }}</el-checkbox
                        >
                      </el-checkbox-group>
                    </el-form-item> -->
                  </div>
                </el-row>
              </div>
            </template>
          </el-form>
        </div>

        <!-- </div> -->
        <div class="edit-wrapper__footer">
          <!-- 新增属性 -->
          <el-button class="cancel" @click="handleGoBack(false)"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="handleSaveAdd"
            v-if="itemStatus != 8"
            >确定</el-button
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import goBack from "@/mixins/goBack";
import {
  getTerminalAttrConfigure,
  updateAttribute,
  getSensorList
} from "@/api";

export default {
  name: "newAttributeConfig",
  mixins: [base, goBack],
  data() {
    return {
      configList: [],
      itemStatus: "",
      // 新增终端属性
      dialogFormVisible: false,
      keyConfig: {
        //摄像头
        cameraWays: [
          {
            title: "一路",
            value: 1
          },
          {
            title: "二路",
            value: 2
          },
          {
            title: "三路",
            value: 3
          },
          {
            title: "四路",
            value: 4
          },
          {
            title: "五路",
            value: 5
          },
          {
            title: "六路",
            value: 6
          },
          {
            title: "七路",
            value: 7
          },
          {
            title: "八路",
            value: 8
          },
          {
            title: "九路",
            value: 9
          },
          {
            title: "十路",
            value: 10
          },
          {
            title: "十一路",
            value: 11
          },
          {
            title: "十二路",
            value: 12
          },
          {
            title: "十三路",
            value: 13
          },
          {
            title: "十四路",
            value: 14
          },
          {
            title: "十五路",
            value: 15
          },
          {
            title: "十六路",
            value: 16
          }
        ],
        //k5摄像头
        k5DvrVideoNumber: [
          {
            title: "一路",
            value: 1
          },
          {
            title: "二路",
            value: 2
          },
          {
            title: "三路",
            value: 3
          },
          {
            title: "四路",
            value: 4
          },
          {
            title: "五路",
            value: 5
          },
          {
            title: "六路",
            value: 6
          },
          {
            title: "七路",
            value: 7
          },
          {
            title: "八路",
            value: 8
          },
          {
            title: "九路",
            value: 9
          },
          {
            title: "十路",
            value: 10
          },
          {
            title: "十一路",
            value: 11
          },
          {
            title: "十二路",
            value: 12
          },
          {
            title: "十三路",
            value: 13
          },
          {
            title: "十四路",
            value: 14
          },
          {
            title: "十五路",
            value: 15
          },
          {
            title: "十六路",
            value: 16
          }
        ],
        //门磁4路
        doorSensorWays: [
          {
            title: "一路",
            value: 1
          },
          {
            title: "二路",
            value: 2
          },
          {
            title: "三路",
            value: 3
          },
          {
            title: "四路",
            value: 4
          }
        ],
        //湿度16路
        humidnessWays: [
          {
            title: "一路",
            value: 1
          },
          {
            title: "二路",
            value: 2
          },
          {
            title: "三路",
            value: 3
          },
          {
            title: "四路",
            value: 4
          },
          {
            title: "五路",
            value: 5
          },
          {
            title: "六路",
            value: 6
          },
          {
            title: "七路",
            value: 7
          },
          {
            title: "八路",
            value: 8
          },
          {
            title: "九路",
            value: 9
          },
          {
            title: "十路",
            value: 10
          },
          {
            title: "十一路",
            value: 11
          },
          {
            title: "十二路",
            value: 12
          },
          {
            title: "十三路",
            value: 13
          },
          {
            title: "十四路",
            value: 14
          },
          {
            title: "十五路",
            value: 15
          },
          {
            title: "十六路",
            value: 16
          }
        ],
        //温控（16路）
        temperatureWays: [
          {
            title: "一路",
            value: 1
          },
          {
            title: "二路",
            value: 2
          },
          {
            title: "三路",
            value: 3
          },
          {
            title: "四路",
            value: 4
          },
          {
            title: "五路",
            value: 5
          },
          {
            title: "六路",
            value: 6
          },
          {
            title: "七路",
            value: 7
          },
          {
            title: "八路",
            value: 8
          },
          {
            title: "九路",
            value: 9
          },
          {
            title: "十路",
            value: 10
          },
          {
            title: "十一路",
            value: 11
          },
          {
            title: "十二路",
            value: 12
          },
          {
            title: "十三路",
            value: 13
          },
          {
            title: "十四路",
            value: 14
          },
          {
            title: "十五路",
            value: 15
          },
          {
            title: "十六路",
            value: 16
          }
        ]
      },
      clickIndex: 0
    };
  },
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.itemId = hasParamsRoute.params.itemId;
    this.itemStatus = hasParamsRoute.params.itemStatus;
    if (this.itemId) {
      this.taskId = hasParamsRoute.params.taskId;
      this.getAttrReq();
    }
  },
  methods: {
    //获取属性默认值
    async getAttrReq() {
      try {
        let res = await getTerminalAttrConfigure({
          id: this.itemId
        });
        this.configList = this.buildData(res.data);
        console.log(this.configList, "~~~");
        this.getSensorList();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //重构数据
    buildData(configList) {
      // let configList = this.configList;
      let combinationId = configList[this.clickIndex].combinationId;
      let itemId = configList[this.clickIndex].itemId;
      let taskId = configList[this.clickIndex].taskId;
      let keysObject = {
        cameraWays: {
          channel: [],
          requireKey: "cameraWays",
          requireName: "摄像头",
          combinationId,
          itemId,
          objectType: "list",
          requireId: "",
          taskId,
          code: "1",
          indexSort: 2
        },
        humidnessWays: {
          code: "68",
          channel: [],
          requireKey: "humidnessWays",
          requireName: "湿度",
          combinationId,
          itemId,
          objectType: "list",
          requireId: "",
          taskId,
          indexSort: 5
        },
        doorSensorWays: {
          code: "3",
          channel: [],
          requireKey: "doorSensorWays",
          requireName: "门磁",
          combinationId,
          itemId,
          objectType: "list",
          requireId: "",
          taskId,
          indexSort: 3
        },
        temperatureWays: {
          code: "4",
          channel: [],
          requireKey: "temperatureWays",
          requireName: "温控",
          combinationId,
          itemId,
          objectType: "list",
          requireId: "",
          taskId,
          indexSort: 4
        }
      };
      configList.map(item => {
        for (let key in keysObject) {
          if (!item.terminalAttrList.some(ele => ele.requireKey == key)) {
            item.terminalAttrList.push(keysObject[key]);
          }
        }
      });
      return configList;
    },
    async getSensorList() {
      try {
        let res = await getSensorList({
          appName: "",
          equThirdTypeId: this.configList[this.clickIndex].thirdClassId,
          sensors: ""
        });
        if (res.code == "OK") {
          let configList = this.configList;
          this.$set(configList[this.clickIndex], "sensorAppList", res.data);
          this.configList = configList;
        }
      } catch (error) {
        printError(error);
      }
    },

    //选择组合
    switchPage(idx) {
      this.clickIndex = idx;
      if (!this.configList[this.clickIndex].thirdClassId) return;
      this.getSensorList();
    },
    // 更新设备属性
    async updateHostAttribute() {
      let configList = _.cloneDeep(this.configList);
      configList.map(item => {
        item.terminalAttrList = item.terminalAttrList.filter(
          ele => ele.requireId || (!ele.requireId && ele.channel.length)
        );
      });
      let combinationList = configList;
      combinationList.map(item => {
        item.g7TerminalAttrVO = {
          terminalAttrList: item.terminalAttrList
        };
        delete item.terminalAttrList;
        delete item.sensorAppList;
      });
      try {
        this.loading = true;
        let res = await updateAttribute({
          combinationList
        });
        if (res.code == "OK") {
          this.$message.success("保存成功");
          this.closeTag(this.$route);
          this.handleGoBack(true);
        }
      } catch (err) {
        printError(err);
      } finally {
        this.loading = false;
      }
    },
    // 提交
    handleSaveAdd() {
      this.updateHostAttribute();
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .edit-wrapper__body {
  .el-row {
    width: 100%;
  }
  .edit-content {
    padding: 20px 20px 2px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .form-item--1 {
    .el-form-item__content {
      width: 260px;
      .e6-vr-select,
      .el-input {
        width: 260px;
      }
    }
  }
  .form-item--2 {
    .el-form-item__content {
      width: 520px;
      .el-input {
        width: 520px;
      }
    }
  }
  .device-name {
    .e6-vr-select {
      width: 220px;
    }
  }
}
</style>
