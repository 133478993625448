var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content-view",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('section',{staticClass:"edit-wrapper"},[_c('div',{staticClass:"edit-wrapper__body"},[_c('div',{staticClass:"switch-page-button"},_vm._l((_vm.configList),function(btn,index){return _c('el-button',{key:index,class:[_vm.clickIndex == index ? 'activeButton' : ''],attrs:{"round":""},on:{"click":function($event){return _vm.switchPage(index)}}},[_vm._v(_vm._s(btn.combinationName))])}),1),_vm._v(" "),(_vm.configList && _vm.configList.length)?_c('el-form',{ref:"form",attrs:{"label-width":"120px"}},[[_c('div',{staticClass:"edit-content"},[_c('el-row',_vm._l((_vm.configList[_vm.clickIndex]['terminalAttrList']),function(config){return _c('div',{key:config.requireKey},[_c('el-col',{attrs:{"span":config.objectType == 'string' ? 8 : 24}},[(config.objectType == 'string')?_c('el-form-item',{attrs:{"label":config.requireName,"prop":"islock"}},[(
                          !(
                            config.requireKey == 'oilControl' ||
                            config.requireKey == 'equipSourceId'
                          )
                        )?_c('el-radio-group',{attrs:{"disabled":config.indexSort == 999 ? true : false},model:{value:(config.requireValue),callback:function ($$v) {_vm.$set(config, "requireValue", $$v)},expression:"config.requireValue"}},[_c('el-radio',{attrs:{"label":"1"}},[_vm._v("是")]),_vm._v(" "),_c('el-radio',{attrs:{"label":"0"}},[_vm._v("否")])],1):_c('span',[_vm._v(":"+_vm._s(config.requireValue))])],1):_vm._e(),_vm._v(" "),(
                        config.objectType == 'list' &&
                          (config.requireKey != 'sensor' ||
                            (config.requireKey == 'sensor' &&
                              _vm.configList[_vm.clickIndex].sensorAppList &&
                              _vm.configList[_vm.clickIndex].sensorAppList.length))
                      )?_c('el-form-item',{attrs:{"label":config.requireName}},[(config.requireKey != 'sensor')?_c('div',[_c('el-checkbox-group',{attrs:{"disabled":config.indexSort == 999 ? true : false},model:{value:(config.channel),callback:function ($$v) {_vm.$set(config, "channel", $$v)},expression:"config.channel"}},_vm._l((_vm.keyConfig[config.requireKey]),function(item){return _c('el-checkbox',{key:item.value,attrs:{"label":item.value,"disabled":config.isReadOnlyChannel &&
                              config.isReadOnlyChannel.includes(item.value)
                                ? true
                                : false}},[_vm._v(_vm._s(item.title))])}),1)],1):_c('div',[_c('el-checkbox-group',{model:{value:(config.channel),callback:function ($$v) {_vm.$set(config, "channel", $$v)},expression:"config.channel"}},_vm._l((_vm.configList[_vm.clickIndex]
                              .sensorAppList),function(item){return _c('el-checkbox',{key:item.id,attrs:{"label":item.id}},[_vm._v(_vm._s(item.appName))])}),1)],1)]):_vm._e()],1)],1)}),0)],1)]],2):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"edit-wrapper__footer"},[_c('el-button',{staticClass:"cancel",on:{"click":function($event){return _vm.handleGoBack(false)}}},[_vm._v("取消")]),_vm._v(" "),(_vm.itemStatus != 8)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSaveAdd}},[_vm._v("确定")]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }